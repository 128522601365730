import React from 'react'

const socialMedia = [
	{
		link: 'https://www.facebook.com/fullstackhq/',
		name: 'Facebook',
		icon: require('../assets/svg/social-facebook-icon.svg')
	},
	{
		link: 'https://www.instagram.com/fullstackhq/',
		name: 'Instagram',
		icon: require('../assets/svg/social-instagram-icon.svg')
	},
	{
		link: 'https://twitter.com/fullstackhq',
		name: 'Twitter',
		icon: require('../assets/svg/social-twitter-icon.svg')
	}
]

function FooterTwo() {
  return(
    <footer className="footer footer-two">
      <div className="container">
        <div className="social-wrapper">
          <ul className="text-center footer-social-media-holder">
            {socialMedia.map((socialMedia, i) => {
              return (
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  key={i}
                  href={socialMedia.link}
                  className="footer-social-media-icon"
                >
                  <img src={socialMedia.icon} alt={socialMedia.name} />
                </a>
              )
            })}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default FooterTwo
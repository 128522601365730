import React from 'react'
import { Link } from 'gatsby'

const fullstackLogo = {
  logoImg: require('../assets/images/fs-logo.png'),
  logoAlt: 'Fullstack HQ'
}

function HeaderTwo() {
  return(
    <header className="header header-two">
      <div className="container">
        <div className="text-center logo-wrapper">
          <Link to='/'>
            <img src={fullstackLogo.logoImg} className="navbar-brand" alt={fullstackLogo.logoAlt} />
          </Link>
        </div>
      </div>
    </header>
  )
}

export default HeaderTwo
import React from 'react'

import { OgTags } from "../components/OpenGraph";
import HeaderTwo from "../components/HeaderTwo"
import FooterTwo from "../components/FooterTwo"
import ContentImage from "../components/ContentImage"

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: false,
  isFullContent: true,
  styleA: true,
  title: "Thank you for choosing Fullstack HQ!",
  desc: `Someone from our team will get in touch as soon as possible`,
  image: require('../assets/images/banner-dev.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Back to home',
      link: '/',
      beacon: false
    }, {
      type: 'outline',
      text: 'Contact us',
      link: '/',
      beacon: true
    }
  ]
}

function ThankYouPage() {
  return(
    <>
      <OgTags
        ogTitle="Thank you for choosing Fullstack HQ!"
        ogUrl="https://fullstackhq.com/thank-you"
      />
      <HeaderTwo />
      <main className="section-message">
        <ContentImage 
          content={cta}  
        />
      </main>
      <FooterTwo />
    </>
  )
}

export default ThankYouPage